import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CCard,
  LVenue,
  LBanquetPlan,
  LBanquetContact,
  ConceptMedia,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'MODEL CASE',
            sub: 'モデルケース',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/model_case/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/model_case/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: 'モデルケース',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead u_mbMedium">
            横浜市内最大級の宴会場「鳳翔」をはじめとした大小12の宴会場、数多くの宴席を手がけてきた熟練のシェフが腕をふるう三彩料理。
            <br />
            お客様の大切なひとときを、各界のVIPをお迎えしてきた経験豊かなスタッフがお手伝いたします。
          </p>
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/banquet/model_case/img_modelcase.png',
                  alt: '',
                },
                title: 'パーティー（周年記念／同窓会等）',
                content: (
                  <>
                    招待状の作成から会場設営、演出まで、イメージに合わせたプランをご提案いたします。
                  </>
                ),
                btnList: [
                  {
                    label: 'モデルケースの詳細',
                    icon: 'document',
                    link: {
                      href: '/assets/files/pdf/modelcase_party.pdf',
                      blank: true,
                    },
                  },
                ],
              },
              {
                img: {
                  src: '/assets/images/banquet/model_case/img_modelcase02.png',
                  alt: '',
                },
                title: 'MICE',
                content: (
                  <>
                    大小12の宴会場を利用した会議・パーティーから二次会、ご宿泊まで個性豊かなおもてなしをご提案いたします。
                  </>
                ),
                btnList: [
                  {
                    label: 'モデルケースの詳細',
                    icon: 'document',
                    link: {
                      href: '/assets/files/pdf/modelcase_MICE.pdf',
                      blank: true,
                    },
                  },
                ],
              },
              {
                img: {
                  src: '/assets/images/banquet/model_case/img_modelcase03.png',
                  alt: '',
                },
                title: 'お別れの会',
                content: (
                  <>
                    故人の遺志やご遺族の希望を取り入れ、心をこめてお手伝いさせていただきます。
                  </>
                ),
                btnList: [
                  {
                    label: 'モデルケースの詳細',
                    icon: 'document',
                    link: {
                      href: '/assets/files/pdf/modelcase_farewell.pdf',
                      blank: true,
                    },
                  },
                ],
              },
              {
                img: {
                  src: '/assets/images/banquet/model_case/img_modelcase04.png',
                  alt: '',
                },
                title: 'ケータリング',
                content: (
                  <>
                    ホームパーティーから5,000名の立食まで、会場の特徴や企画の個性に合わせたさまざまなプランをご提案いたします。
                  </>
                ),
                btnList: [
                  {
                    label: 'モデルケースの詳細',
                    icon: 'document',
                    link: {
                      href: '/assets/files/pdf/modelcase_catering.pdf',
                      blank: true,
                    },
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </div>
      <div className={`l_banquetIncs u_bgMix`}>
        <LBanquetPlan />
      </div>
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
